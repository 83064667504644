<template>
  <v-card flat>
    <v-card-text>

<!--      <table style="width: 100%">-->
<!--        <thead>-->
<!--        <tr>-->
<!--          <th style="text-align: right;font-size: 11px">-->
            <small style="float: right;color: black">ข้อมูล ณ วันที่ {{ date }} น.</small>
<!--          </th>-->
<!--        </tr>-->
<!--      </table>-->


      <table>
        <thead>
        <tr>
          <th colspan="7">
            <small style="text-align: center;font-size: 16px;color: black;float: left">รายงานสรุปสินค้าผลิตเสีย</small><br><br>
            <small style="text-align: center;font-size: 14px;color: black;float: left">
              จากวันที่ {{ startDate }} ถึงวันที่ {{ endDate }}
            </small>
            <br><br>
          </th>
        </tr>
        <tr style="background: black;
            color: white;
            border-radius: 50%;
            border: 2px solid black;
            font-size: 18px;
            padding: 10px;">
          <th style="color:white;width: 5%" scope="col"># <br> <span style="font-size: 11px">No.</span></th>
          <th style="color:white;width: 15%;text-align: left" scope="col">
            ชื่อสินค้า<br>
            <span style="font-size: 11px">Product Name</span></th>
          <th style="color:white;width: 5%" scope="col">รหัส<br><span style="font-size: 11px">Code</span></th>
          <th style="color:white;width: 5%" scope="col">ราคา/หน่วย<br> <span style="font-size: 11px">Unit Price</span></th>
          <th style="color:white;width: 15%" scope="col">วัน/เวลา<br> <span style="font-size: 11px">Date Time</span></th>
          <th style="color:white;width: 15%" scope="col">หมายเหต<br><span style="font-size: 11px">Other</span></th>
          <th style="color:white;width: 5%" scope="col">พนักงาน<br><span style="font-size: 11px">Employee Name</span></th>
          <th scope="col" style="text-align: center;width: 5%;color: black">
            จำนวน<br>
            <span style="font-size: 11px;color: white">Quantity</span>
          </th>
          <th scope="col" style="color: white;width: 10%;text-align: center">รวม <br><span
            style="font-size: 11px"></span><span style="font-size: 11px">Amount</span>
          </th>
        </tr>
        </thead>
        <tbody v-if="Products && Products.length > 0">
        <tr class="bt-bottom" v-for="(product,index) in Products" style="font-size: 14px;font-weight: 700">
          <td>{{ index + 1 }}</td>
          <td>{{ product.product_name }}</td>
          <td>{{ product.product_code }}</td>
          <td>{{ product.product_costs_present }}</td>
          <td>{{ product.insert_date }} {{ product.insert_time }} น.
          </td>
          <td>{{ product.product_amount_comment }}</td>
          <td>{{ product.employee_name }}</td>
          <td style="text-align: center">{{ product.product_amount }}</td>
          <td style="text-align: center">
            {{ product.product_costs_present * product.product_amount }}
          </td>
        </tr>
<!--        <tr>-->
<!--          <td colspan="9">-->
<!--            <hr>-->
<!--          </td>-->
<!--        </tr>-->
        <tr style="text-align: right;font-size: 20px">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td colspan="" style="color: black">
            <h3 style="color: black !important;">จำนวน Amount </h3>
          </td>
          <td style="color: black">
            <h3 style="color: black !important;">{{ amount }} (ชิ้น)</h3>
          </td>
        </tr>
        <tr style="text-align: right">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td colspan="" style="color: black">
            <h3 style="color: black !important;">รวม Total</h3>
          </td>
          <td style="color: black">
            <h3 style="color: black !important;">{{ total }} (บาท) </h3>
          </td>
        </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'

import instance_product_worn_out from "@/services/productWornOut";

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data() {
    return {
      date: '',
      startDate: '',
      endDate: '',
      Products: [],
    }
  },
  computed: {

    total :function (){
      let sum = 0;
      Object.entries(this.Products).forEach(([key, val]) => {
        //   if(isset($explode_product_amount) && count($explode_product_amount) > 1){
        //     $total+= $product->product_costs_present*$explode_product_amount[1];
        //   }else{
        sum += val.product_costs_present * val.product_amount
      });
      return sum;
    },
    amount :function (){
      let amount = 0;
      Object.entries(this.Products).forEach(([key, val]) => {
        // esent*$product->product_amount;*/
        //
        // $explode_product_amount = explode('-',$product->product_amount);
        // if(isset($explode_product_amount) && count($explode_product_amount) > 1){
        //   $amount+= $explode_product_amount[1];
        amount += val.product_amount
      });
      return amount;
    },
  },
  methods: {
    nowDate() {
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      var d = `${year}-${String(month).padStart(2, '0')}-${day}`;
      return d;
    },
    getData() {
      var data = {
        search: this.$route.query.search,
        type: this.$route.query.type,
        date: this.$route.query.date,
        is_worn_out: this.$route.query.is_worn_out ? 'Y' : 'N',
      }
      instance_product_worn_out.getExportData(data).then(res => {
        this.Products = res.data.products
      }).catch(err => {

      })

    }
  },
  watch: {},

  created() {

  },
  mounted() {
    this.date = this.nowDate()
    this.startDate = this.$route.query.date.split(',')[0];
    this.endDate = this.$route.query.date.split(',')[1];
    this.getData()
    window.onload = function () {
      window.print();
    }
  },
}
</script>

<style scoped>
*{
  /*size: A4;*/
  /*margin: 0;*/
  /*padding: 0;*/
/*  color: black;*/
}

.bt-bottom {
  border-bottom: 1pt solid lightgrey;
}
th {
  text-align: left;
  color: white !important;;
}
td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: grey !important;;
}

table {
  /*margin: 0 20px;*/
  border-spacing : 0;
  border-collapse: collapse;
  width: 100%;
}
.table tr th {
  padding: 1.2em !important;
  vertical-align: top;
  border-top: 1px solid #f4f5f8;
  font-size: 10px;
  color: white !important;
}

.table tr td {
  padding: 1.5em !important;
  vertical-align: top;
  border-top: 1px solid #f4f5f8;
  font-size: 10px;
  color: white;
}

.table th {
  background-color: rgba(232, 234, 244, 0.6);
  font-size: 10px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: none;
}

.table-striped tbody tr:nth-of-type(2n) {
  background-color: rgba(232, 234, 244, 0.3)
}

table.dataTable.no-footer {
  border-bottom: unset !important;
}

.dataTables_filter {
  display: none;
}

html,body {
  /*margin: 20px;*/
  /*padding: 20px;*/
  color: black;
  font-size: 10px;

  size: A4;
}

.title {
  /* บีคอนส์ */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 20px;
  color: #3D438D;
}

.marker {
  top: -0.9em !important;
}

.m-checkbox.m-checkbox--brand.m-checkbox--solid > input:checked ~ span {
  background: #2e6da4 !important;
}

.dataTables_wrapper .pagination .page-item:hover > .page-link {
  background: #2e6da4 !important;
}

#removeChechBox {
  cursor: pointer;
}

.check_beacon {
  pointer-events: none
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  border: 1px solid transparent !important;
  border-radius: 18px !important;
  background: #2e6da4 !important;
  color: #282a3c !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: #2e6da4 !important;
  color: white !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: white !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  border: 1px solid transparent !important;
  border-radius: 18px !important;
  color: #282a3c !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  border: 1px solid transparent !important;
  border-radius: 18px !important;
  color: #fff !important;
  background-color: #0a6aa1 !important;
  background: -webkit-linear-gradient(top, #2e6da4 0%, #2e6da4 100%) !important;
}

#eventTable .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: white !important;
}

#eventTable .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
  color: #fff !important;
}

#filter_bar {
  padding: 20px;
  height: auto;
}

@media (min-width: 426px) {
  #filter_bar {
    position: fixed;
    top: 107px;
    left: 0;
    right: 0;
    z-index: 10;
  }

  #main-content {
    padding-top: 90px;
  }
}

@media (min-width: 1025px) {
  #filter_bar {
    left: 255px;
    top: 70px;
  }
}

.dataTables_wrapper .dataTable td .m-checkbox, .dataTables_wrapper .dataTable th .m-checkbox {
  left: 7px;
}
@page {
  size: A4;
  color: black;
}

.table th {
  padding: 1.2em !important;
  vertical-align: top;
  border-top: 1px solid #f4f5f8;
  font-size: 10px;
  color: black;
}

.table td {
  padding: 1.5em !important;
  vertical-align: top;
  border-top: 1px solid #f4f5f8;
  font-size: 10px;
  color: black;
}

.table th {
  background-color: rgba(232, 234, 244, 0.6);
  font-size: 10px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: none;
}

.table-striped tbody tr:nth-of-type(2n) {
  background-color: rgba(232, 234, 244, 0.3)
}

table.dataTable.no-footer {
  border-bottom: unset !important;
}

.dataTables_filter {
  display: none;
}

html,body {
  margin: 0;
  padding: 0;
  color: black;
  font-size: 10px;
}

.title {
  /* บีคอนส์ */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 20px;
  color: #3D438D;
}

.marker {
  top: -0.9em !important;
}

.m-checkbox.m-checkbox--brand.m-checkbox--solid > input:checked ~ span {
  background: #2e6da4 !important;
}

.dataTables_wrapper .pagination .page-item:hover > .page-link {
  background: #2e6da4 !important;
}

#removeChechBox {
  cursor: pointer;
}

.check_beacon {
  pointer-events: none
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  border: 1px solid transparent !important;
  border-radius: 18px !important;
  background: #2e6da4 !important;
  color: #282a3c !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: #2e6da4 !important;
  color: white !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: white !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  border: 1px solid transparent !important;
  border-radius: 18px !important;
  color: #282a3c !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  border: 1px solid transparent !important;
  border-radius: 18px !important;
  color: #fff !important;
  background-color: #0a6aa1 !important;
  background: -webkit-linear-gradient(top, #2e6da4 0%, #2e6da4 100%) !important;
}

#eventTable .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: white !important;
}

#eventTable .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
  color: #fff !important;
}

#filter_bar {
  padding: 20px;
  height: auto;
}

@media (min-width: 426px) {
  #filter_bar {
    position: fixed;
    top: 107px;
    left: 0;
    right: 0;
    z-index: 10;
  }

  #main-content {
    padding-top: 90px;
  }
}

@media (min-width: 1025px) {
  #filter_bar {
    left: 255px;
    top: 70px;
  }
}

.dataTables_wrapper .dataTable td .m-checkbox, .dataTables_wrapper .dataTable th .m-checkbox {
  left: 7px;
}


@media print {
  html,body {
  }
  table{
    /*width: 100%;*/
    /*margin: 0 20px;*/
  }
}

</style>
